import { configuration } from "../../config/configuration";
import { ICertificate, IDomain, IRule } from "../data/domain";
import { BaseService } from "./api-clients/base-service";
import { BearerTokenApiClient } from "./api-clients/bearer-token-api-client";
import { authentication } from "./authentication";

export class DomainService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, "domains");
  }

  createDomain(domainName: string, domainURL: string): Promise<IDomain[]> {
    const userID = authentication.getUser()?.sub || "";
    const url = this.url(`/create-domain`);
    return this.apiClient.post(url, {
      domainName,
      domainURL,
      userID,
    });
  }

  getDomains(): Promise<IDomain[]> {
    const userID = encodeURI(authentication.getUser()?.sub || "undefined");
    const url = this.url(`/${userID}`);
    return this.apiClient.get(url);
  }

  getDomainByName(name: string): Promise<IDomain> {
    const domainName = encodeURI(name);
    const url = this.url(`/domain/${domainName}`);
    return this.apiClient.get(url);
  }

  deleteDomainByName(name: string): Promise<IDomain> {
    const domainName = encodeURI(name);
    const url = this.url(`/domain/${domainName}`);
    return this.apiClient.delete(url);
  }

  copyDesignToDeployed(name: string, deleteIndexes: number[]): Promise<any> {
    const domainName = encodeURI(name);
    const url = this.url(`/change-rule/design-to-deployed/${domainName}`);
    return this.apiClient.post(url, { deleteIndexes });
  }

  copyDeployedToDesign(name: string): Promise<any> {
    const domainName = encodeURI(name);
    const url = this.url(`/change-rule/deployed-to-design/${domainName}`);
    return this.apiClient.get(url);
  }

  postRuleToDesignedStatus(domain: string, rule: IRule): Promise<any> {
    const url = this.url(`/add-rule/designed-status/${domain}`);
    return this.apiClient.post(url, {
      rule: { path: rule.path, targetURL: rule.targetURL },
    });
  }

  updateCertificate(domain: string, certificate: ICertificate): Promise<any> {
    const url = this.url(`/domain/certificate/${domain}`);
    return this.apiClient.post(url, certificate);
  }
}
export const domainService = new DomainService(new BearerTokenApiClient());
