import axios from "axios";
import { IRule } from "../data/domain";
import { authentication } from "./authentication";
import { configuration } from "../../config/configuration";

export class OgunService {
  addDomainTo(domainURL: string): Promise<any> {
    const data = JSON.stringify({
      name: domainURL,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${configuration.cfApi.hostingUrl}/domain/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      data: data,
    };

    return axios.request(config);
  }

  deleteDomain(domainURL: string): Promise<any> {
    const data = JSON.stringify({
      name: domainURL,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${configuration.cfApi.hostingUrl}/domain/delete`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      data: data,
    };

    return axios.request(config);
  }

  updateRoute(domainURL: string, rules: IRule[]): Promise<any> {
    const targets: any = {};
    rules.map((rule) => {
      targets[rule.path] = { target: rule.targetURL };
    });

    const data = JSON.stringify({
      domain: domainURL,
      targets: targets,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${configuration.cfApi.hostingUrl}/route/update`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      data: data,
    };

    return axios.request(config);
  }
}
export const ogunService = new OgunService();
