import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./framework/reportWebVitals";
import { Kernel } from "./framework/Kernel";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { configuration } from "./config/configuration";
import { theme } from "./framework/theme";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { FrigadeProvider } from "@frigade/react";
import { authentication } from "./framework/services/authentication";

const newTheme = createTheme({
  palette: {
    primary: grey,
    secondary: {
      main: "#f5fb8b",
    },
    error: {
      main: "#c04f2f",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={configuration.userRegistry.domain}
      clientId={configuration.userRegistry.managementClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <FrigadeProvider publicApiKey={configuration.frigade.publicApiKey}>
        <ThemeProvider theme={newTheme}>
          <ChakraProvider theme={theme}>
            <Kernel />
          </ChakraProvider>
        </ThemeProvider>
      </FrigadeProvider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
