import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authentication } from "../../services/authentication";
import { Spinner } from "@chakra-ui/react";
import { useUser } from "@frigade/react";

const Page = () => {
  const navigate = useNavigate();
  const { setUserId } = useUser();
  useEffect(() => {
    const completeLogin = async () => {
      try {
        await authentication.handleRedirectCallback();
        setUserId(authentication.getUser()?.sub || null);
        navigate("/domains");
      } catch (err) {
        console.error(err);
      }
    };
    completeLogin();
  }, [navigate]);
  return <Spinner size="xl" />;
};

export const LoginCallbackPage = Page;
