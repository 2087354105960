import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  components: {
    Button: {
      variants: {
        grey: {
          bg: "#494f4f",
          color: "white",
        },
      },
    },
  },
});
