import {
  Text,
  Button,
  Flex,
  Box,
  Spinner,
  Input,
  useToast,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { stripeService } from "../../services/stripe-service";
import { useEffect, useState } from "react";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { authentication } from "../../services/authentication";
import { domainService } from "../../services/domain-service";
import "./NewDomainPage.scss";
import { useNavigate } from "react-router-dom";
import { ogunService } from "../../services/ogun-service";
import { configuration } from "../../../config/configuration";

const Page = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [sessionId, setSessionId] = useState<string>("");
  const [domainName, setDomainName] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [domains, loadingDomains] = useDataFetcher({
    serviceCall: () => domainService.getDomains(),
    onError: (e) => {
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      setTimeout(function () {
        navigate("/");
      }, 5000);
    },
  });
  const [customer, loadingCustomer] = useDataFetcher({
    serviceCall: () =>
      stripeService.getCustomer(authentication.getUser()?.email),
    onError: (e) => {
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      setTimeout(function () {
        navigate("/");
      }, 5000);
    },
  });
  const [subscriptions, loadingSubscriptions] = useDataFetcher({
    serviceCall: () => stripeService.getCustomerSubscriptions(customer.id),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
    conditionForExecution: customer !== undefined,
    dependencies: [customer],
  });
  const [isAddingDomainLoading, setIsAddingDomainLoading] = useState(false);

  const isInsideMember = (email?: string) => {
    if (!email) {
      return false;
    }
    return (
      email.slice(email.indexOf("@") + 1, email.indexOf(".")) === "merthin"
    );
  };

  const checkoutSession = async () => {
    if (
      isInsideMember(authentication.getUser()?.email) &&
      configuration.userRegistry.domain ===
        "demy-reverse-proxy-prod.us.auth0.com"
    ) {
      const body = await stripeService.createCheckoutSession(
        "price_1NiNfaK6WVzGeHbB7FuYXDua",
        customer.id
      );
      window.location.href = body.url;
    } else {
      const body = await stripeService.createCheckoutSession(
        configuration.stripe.priceId,
        customer.id
      );
      window.location.href = body.url;
    }
  };

  const portalSession = async () => {
    const body = await stripeService.createPortalSession(customer.id);
    window.location.href = body.url;
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id") || "");
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  const resetForm = () => {
    setDomainName("");
    setDomainUrl("");
  };

  const needSubscription = () => {
    return success ? (
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"2rem"}
        w={"100%"}
      >
        <Text>Subscription added successfully</Text>
        <Button onClick={() => portalSession()}>
          Manage your subscriptions
        </Button>
      </Flex>
    ) : (
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"2rem"}
      >
        <Text>You need to subscribe to add a new domain</Text>
        <Button onClick={() => checkoutSession()}>Add new Domain</Button>
        <Text>{message}</Text>
      </Flex>
    );
  };

  const handleNewDomain = async () => {
    setIsAddingDomainLoading(true);
    try {
      const data = await ogunService.addDomainTo(domainUrl);
      if (data.data.status === "failed") {
        toast({
          title: "Domain is invalid",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else if (data.data.status === "error") {
        toast({
          title: "Error",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        await domainService.createDomain(domainName, domainUrl);
        navigate("/");
      }
    } catch (error) {
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    setIsAddingDomainLoading(false);
  };

  const newDomainForm = () => {
    return (
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDir={"column"}
        gap={"1rem"}
        w={"40%"}
      >
        <Text fontSize={"2xl"}>Add New Domain</Text>
        <Box w={"100%"}>
          <Input
            borderColor={"black"}
            placeholder="Domain Name"
            onChange={(event) => setDomainName(event.target.value)}
          />
        </Box>
        <Box w={"100%"}>
          <Input
            borderColor={"black"}
            placeholder="Domain URL"
            onChange={(event) => setDomainUrl(event.target.value)}
          />
        </Box>
        <Flex justify={"flex-end"} gap={"1rem"} w={"100%"}>
          <Button
            w={"100%"}
            colorScheme="blue"
            isDisabled={!domainName || !domainUrl}
            onClick={handleNewDomain}
          >
            Add
          </Button>
          <Button w={"100%"} onClick={() => resetForm()}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Box className="NewDomainsPage">
      {loadingDomains ||
      loadingCustomer ||
      loadingSubscriptions ||
      !subscriptions ||
      !domains ||
      isAddingDomainLoading ? (
        <Flex justifyContent={"center"} alignItems={"center"} w={"100%"}>
          <Spinner thickness="0.25rem" color="#272349" size={"xl"} />
        </Flex>
      ) : domains?.length >= subscriptions.length || customer === "" ? (
        needSubscription()
      ) : (
        newDomainForm()
      )}
    </Box>
  );
};

export const NewDomainPage = withAuthProtection(Page);
