import { BaseService } from "./api-clients/base-service";
import { BearerTokenApiClient } from "./api-clients/bearer-token-api-client";
import { authentication } from "./authentication";

export class StripeService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, "stripe");
  }

  createCheckoutSession(api_id: string, customerID?: string): Promise<any> {
    const url = this.url("/create-checkout-session");
    return this.apiClient.post(url, {
      api_id: api_id,
      customerID: customerID,
    });
  }

  createPortalSession(customID: string): Promise<any> {
    const url = this.url("/create-portal-session");
    return this.apiClient.post(url, {
      customID,
    });
  }

  getCustomer(email?: string): Promise<any> {
    const url = this.url("/customer");
    return this.apiClient.post(url, {
      email: email,
    });
  }

  getCustomerSubscriptions(customerID: string): Promise<any> {
    const url = this.url("/subscriptions");
    return this.apiClient.post(url, {
      customerID: customerID,
    });
  }
}
export const stripeService = new StripeService(new BearerTokenApiClient());
