import {
  Button,
  ButtonProps,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowRightIcon } from "@chakra-ui/icons";
import data from "../../../config/config.json";

interface NavButtonProps extends ButtonProps {
  icon: string;
  label: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, ...buttonProps } = props;
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  return (
    <Button
      variant="grey"
      justifyContent={isDesktop ? "start" : "center"}
      {...buttonProps}
    >
      <HStack spacing="3">
        <ArrowRightIcon boxSize="3" color={data.primaryColor} />
        {isDesktop && <Text>{label}</Text>}
      </HStack>
    </Button>
  );
};
