import { Outlet, useNavigate } from "react-router-dom";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { Navbar } from "../../components/navigation/Navbar";
import { useEffect, useState } from "react";
import { FrigadeChecklist, useFlows } from "@frigade/react";
import "./MainLayout.scss";
import { authentication } from "../../services/authentication";

export const MainLayout = () => {
  const navigate = useNavigate();
  const { getFlowStatus } = useFlows();
  const [flowStatus, setFlowStatus] = useState<string | number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const getFrigadeFlowStatus = async () => {
    const data = await getFlowStatus("flow_klNTIWEtg1wn6D5P");
    if (data === null && typeof flowStatus === "number") {
      setFlowStatus(flowStatus + 1);
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setFlowStatus(data);
    }
  };

  useEffect(() => {
    if (authentication.getUser() === null) {
      navigate("/login");
    }

    if (flowStatus !== "COMPLETED_FLOW") {
      getFrigadeFlowStatus();
    }
  }, [flowStatus]);
  return (
    <Box
      as="section"
      height="100vh"
      overflowY="hidden"
      display="flex"
      flexDirection="row"
    >
      {isLoading ? (
        <Flex justifyContent={"center"} alignItems={"center"} w={"100%"}>
          <Spinner thickness="0.25rem" color="#272349" size={"xl"} />
        </Flex>
      ) : false ? (
        <Flex className="MainLayoutCheckList">
          <FrigadeChecklist
            flowId="flow_klNTIWEtg1wn6D5P"
            title="Getting Started"
            subtitle="Follow our quick checklist to get started"
            onComplete={() => setFlowStatus("COMPLETED_FLOW")}
            onStepCompletion={(step, index, nextStep) => {
              if (nextStep === null) {
                setFlowStatus("COMPLETED_FLOW");
              }
              return true;
            }}
            hideOnFlowCompletion={true}
          />
        </Flex>
      ) : (
        <>
          <Navbar />
          <div className="page-container">
            <Outlet />
          </div>
        </>
      )}
    </Box>
  );
};
