import {
  Text,
  Button,
  Flex,
  Input,
  Spinner,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { IDomain } from "../../data/domain";
import { useEffect, useState } from "react";
import { domainService } from "../../services/domain-service";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import data from "../../../config/config.json";
import { ogunService } from "../../services/ogun-service";
import { useNavigate } from "react-router-dom";
import React from "react";
import { FrigadeChecklist, useFlows } from "@frigade/react";
import { configuration } from "../../../config/configuration";
import { Routes } from "./components/Routes";

const Page = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const domainName = currentUrl.slice(currentUrl.lastIndexOf("/") + 1);
  const [domain, loadingDomain, setDomain] = useDataFetcher({
    serviceCall: () => domainService.getDomainByName(domainName),
    onError: (e) => {
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      setTimeout(function () {
        navigate("/");
      }, 5000);
    },
  });
  const [deleteIndexes, setDeleteIndexes] = useState<number[]>([]);
  const [originCertificate, setOriginCertificate] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishingLoading, setIsPublishingLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const { getFlowStatus, markStepCompleted, markStepNotStarted } = useFlows();
  const [flowStatus, setFlowStatus] = useState<string | number>(0);

  const getFrigadeFlowStatus = async () => {
    const data = await getFlowStatus(configuration.frigade.checklistFlow);
    if (data === null && typeof flowStatus === "number") {
      setFlowStatus(flowStatus + 1);
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setFlowStatus(data);
    }
  };

  useEffect(() => {
    if (flowStatus !== "COMPLETED_FLOW") {
      getFrigadeFlowStatus();
    }
  }, [flowStatus]);

  useEffect(() => {
    if (originCertificate && privateKey) {
      markStepCompleted(
        configuration.frigade.checklistFlow,
        "checklist-step-one"
      );
    } else {
      markStepNotStarted(
        configuration.frigade.checklistFlow,
        "checklist-step-one"
      );
    }
  }, [originCertificate, privateKey]);

  const handleSubmitCertificate = async () => {
    if (domain) {
      domainService.updateCertificate(domain.domainName, {
        originCertificate: originCertificate,
        privateKey: privateKey,
      });
    }
    onClose();
    window.location.reload();
  };

  const handlePublish = async () => {
    try {
      if (!domain) {
        return;
      }
      setIsPublishingLoading(true);
      let copy = { ...domain } as IDomain;
      const currentDesign = copy.designedStatus.filter(
        (_, index) => !deleteIndexes.includes(index)
      );
      const data = await ogunService.updateRoute(
        domain.domainURL,
        currentDesign
      );
      if (data.data.status === "failed") {
        toast({
          title: "Invalid",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsPublishingLoading(false);
      } else if (data.data.status === "error") {
        toast({
          title: "Error",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsPublishingLoading(false);
      } else {
        await domainService.copyDesignToDeployed(domainName, deleteIndexes);
        copy.designedStatus = JSON.parse(JSON.stringify(currentDesign));
        copy.deployedStatus = JSON.parse(JSON.stringify(copy.designedStatus));
        setDeleteIndexes([]);
        setDomain({ ...copy });
        setIsPublishingLoading(false);
        toast({
          title: "Publish successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        markStepCompleted(
          configuration.frigade.checklistFlow,
          "checklist-step-two"
        );
      }
    } catch (error) {
      setIsPublishingLoading(false);
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleReset = () => {
    try {
      domainService.copyDeployedToDesign(domainName);
      let copy = { ...domain } as IDomain;
      copy.designedStatus = JSON.parse(JSON.stringify(copy.deployedStatus));
      setDeleteIndexes([]);
      setDomain({ ...copy });
      toast({
        title: "Reset successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const renderRules = () => {
    if (!domain) {
      return;
    }

    return (
      <Routes
        domain={domain}
        setDomain={setDomain}
        deleteIndexes={deleteIndexes}
        setDeleteIndexes={setDeleteIndexes}
        isLoading={isLoading}
      />
    );
  };

  useEffect(() => {
    if (!loadingDomain && domain) {
      setOriginCertificate(domain.certificate.originCertificate || "");
      setPrivateKey(domain.certificate.privateKey || "");
    }
  }, [loadingDomain]);

  return (
    <Flex w={"100%"} h={"100vh"} bg={"#D6D6D6"} overflowY={"auto"}>
      {loadingDomain || !domain || isPublishingLoading ? (
        <Flex justifyContent={"center"} alignItems={"center"} w={"100%"}>
          <Spinner thickness="0.25rem" color="#272349" size={"xl"} />
        </Flex>
      ) : (
        <Flex w={"100%"} flexDir={"column"}>
          <Flex
            w={"100%"}
            position={"relative"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"2rem"}
            padding={"2rem"}
            paddingBottom={"0rem"}
          >
            <Flex alignItems={"center"} gap={"2rem"}>
              <Text fontSize={"5xl"} textTransform={"uppercase"}>
                {domain.domainURL}
              </Text>
              <Button onClick={onOpen}>View Certificate</Button>
              <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Certificate</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl>
                      <FormLabel>Origin Certificate</FormLabel>
                      <Input
                        ref={initialRef}
                        value={originCertificate}
                        placeholder="Origin Certificate"
                        onChange={(event) =>
                          setOriginCertificate(event.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Private Key</FormLabel>
                      <Input
                        value={privateKey}
                        placeholder="Private Key"
                        onChange={(event) => setPrivateKey(event.target.value)}
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleSubmitCertificate()}
                    >
                      Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>
            <Flex gap={"1rem"}>
              <Button
                isDisabled={
                  JSON.stringify(domain.designedStatus) ===
                  JSON.stringify(domain.deployedStatus)
                }
                size={"lg"}
                borderRadius={0}
                bg={"white"}
                textTransform={"uppercase"}
                border={"1px solid black"}
                onClick={() => handleReset()}
              >
                Reset
              </Button>
              <Button
                isDisabled={
                  JSON.stringify(domain.designedStatus) ===
                    JSON.stringify(domain.deployedStatus) &&
                  deleteIndexes.length === 0
                }
                size={"lg"}
                borderRadius={0}
                textTransform={"uppercase"}
                bg={data.primaryColor}
                color={"white"}
                onClick={() => handlePublish()}
              >
                Publish
              </Button>
            </Flex>
          </Flex>
          <Flex
            position={"relative"}
            justifyContent={"center"}
            flexDir={"column"}
            alignItems={"center"}
            gap={"2rem"}
            h={"100%"}
            bg={"white"}
            margin={"2rem"}
            marginTop={"1rem"}
            padding={"2rem"}
            borderRadius={"1rem"}
            flexGrow={1}
            pos={"relative"}
          >
            {flowStatus !== "COMPLETED_FLOW" && (
              <Flex
                position={"absolute"}
                margin={"auto"}
                zIndex={1}
                top={"0%"}
                w={"100%"}
              >
                <FrigadeChecklist
                  flowId={configuration.frigade.checklistFlow}
                  checklistStyle="carousel"
                  hideOnFlowCompletion={true}
                />
              </Flex>
            )}
            {renderRules()}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export const DomainDetailPage = withAuthProtection(Page);
