import {
  Box,
  Button,
  Flex,
  Input,
  Spinner,
  useToast,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { AddIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import React from "react";
import { domainService } from "../../../services/domain-service";
import { validateUrlService } from "../../../services/validateUrl";
import { IDomain, IRule } from "../../../data/domain";
import data from "../../../../config/config.json";

interface RouteProps {
  domain: IDomain;
  setDomain: React.Dispatch<React.SetStateAction<IDomain | undefined>>;
  deleteIndexes: number[];
  setDeleteIndexes: React.Dispatch<React.SetStateAction<number[]>>;
  isLoading: boolean;
}

export const Routes: FunctionComponent<RouteProps> = (props) => {
  const toast = useToast();
  const [path, setPath] = useState("");
  const [targetUrl, setTargetUrl] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleNewRule = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let isValid = false;

    try {
      setIsLoading(true);
      const result = await validateUrlService.postValidateUrl(
        `https://${targetUrl}`
      );
      isValid = result;
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

    if (isValid && props.domain) {
      let copy = { ...props.domain };
      copy.designedStatus.push({ path: path, targetURL: targetUrl });
      domainService.postRuleToDesignedStatus(props.domain.domainName, {
        path: path,
        targetURL: targetUrl,
      });
      props.setDomain({ ...copy });
      setIsAdding(false);
      setPath("");
      setTargetUrl("");
      toast({
        title: "New rule added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      toast({
        title: "Target URL is not valid",
        description: "Please make sure the url link is correct",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const getBorderColor = (index: number) => {
    const exist = props.deleteIndexes.includes(index);
    if (exist) {
      return data.deleteElementColor;
    }
    if (!props.domain) {
      return "black";
    }
    return index >= props.domain?.deployedStatus.length
      ? data.newElementColor
      : "black";
  };

  return (
    <Flex
      flexDir={"column"}
      gap={"2rem"}
      alignItems={"center"}
      position={"relative"}
      h={"fit-content"}
    >
      <Box
        position={"absolute"}
        w={"1px"}
        bg="black"
        h="100%"
        marginLeft={"auto"}
        marginRight={"auto"}
      />
      {props.domain?.designedStatus.map((rule: IRule, index: number) => {
        return (
          <Flex marginLeft={"14rem"}>
            <InputGroup w={"8rem"}>
              <InputLeftAddon children="/" />
              <Input
                borderWidth={2}
                borderRightWidth={1}
                borderColor={getBorderColor(index)}
                borderStyle={
                  getBorderColor(index) !== "black" ? "dashed" : "solid"
                }
                borderRightRadius={0}
                value={rule.path}
              />
            </InputGroup>
            <Input
              w={"18.8rem"}
              borderWidth={2}
              borderLeftWidth={1}
              borderColor={getBorderColor(index)}
              borderStyle={
                getBorderColor(index) !== "black" ? "dashed" : "solid"
              }
              borderLeftRadius={0}
              borderRightRadius={0}
              value={rule.targetURL}
            />
            <Button
              border={"1px solid black"}
              borderLeftRadius={0}
              onClick={() => {
                if (!props.deleteIndexes.includes(index)) {
                  props.setDeleteIndexes([...props.deleteIndexes, index]);
                } else {
                  props.setDeleteIndexes(
                    props.deleteIndexes.filter((current) => current !== index)
                  );
                }
              }}
            >
              {!props.deleteIndexes.includes(index) ? (
                <DeleteIcon boxSize={4} />
              ) : (
                <CloseIcon boxSize={4} />
              )}
            </Button>
          </Flex>
        );
      })}
      {isAdding ? (
        <Flex marginLeft={"17rem"}>
          <InputGroup w={"8rem"}>
            <InputLeftAddon children="/" />
            <Input
              borderWidth={2}
              borderRightWidth={1}
              borderColor={data.newElementColor}
              borderRightRadius={0}
              value={path}
              onChange={(event) => setPath(event.target.value)}
            />
          </InputGroup>
          <Input
            w={"18.8rem"}
            borderWidth={2}
            borderLeftWidth={1}
            borderColor={data.newElementColor}
            borderLeftRadius={0}
            borderRightRadius={0}
            value={targetUrl}
            onChange={(event) => setTargetUrl(event.target.value)}
          />
          {isLoading || props.isLoading ? (
            <Button
              border={"1px solid black"}
              borderLeftRadius={0}
              borderRightRadius={0}
              isDisabled
            >
              <Spinner thickness="0.25rem" color="#272349" size={"sm"} />
            </Button>
          ) : (
            <Button
              border={"1px solid black"}
              borderLeftRadius={0}
              borderRightRadius={0}
              isDisabled={!path || !targetUrl}
              onClick={(event) => handleNewRule(event)}
            >
              <AddIcon boxSize={4} />
            </Button>
          )}
          <Button
            border={"1px solid black"}
            borderLeftRadius={0}
            onClick={() => {
              setPath("");
              setTargetUrl("");
              setIsAdding(false);
            }}
          >
            <DeleteIcon boxSize={4} />
          </Button>
        </Flex>
      ) : (
        <Button
          bg={"white"}
          padding={"1.5rem 0.5rem"}
          border={"1px solid black"}
          borderRadius={"10rem"}
          onClick={() => setIsAdding(true)}
        >
          <AddIcon boxSize={8} />
        </Button>
      )}
    </Flex>
  );
};
