import {
  Box,
  Spinner,
  Flex,
  Text,
  Button,
  Image,
  useToast,
} from "@chakra-ui/react";
import { withAuthProtection } from "../../services/protect-route-element";
import { domainService } from "../../services/domain-service";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { DomainList } from "./components/DomainList";
import "./DomainsPage.scss";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { LocalIcons } from "../../assets/icons/icons";

const Page = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [domains, loadingDomains, setDomains] = useDataFetcher({
    serviceCall: () => domainService.getDomains(),
    onError: (e) =>
      toast({
        title: e.message,
        description: "Error on loading",
        status: "error",
        duration: 2500,
        isClosable: true,
        position: "top",
      }),
  });

  return (
    <Box className="DomainsPage">
      {loadingDomains ? (
        <Flex justifyContent={"center"} alignItems={"center"} w={"100%"}>
          <Spinner thickness="0.25rem" color="#272349" size={"xl"} />
        </Flex>
      ) : (
        <Flex w={"100%"} flexDir={"column"} gap={"2rem"}>
          <Flex alignItems={"center"} gap={"1.5rem"}>
            <Text fontSize={"5xl"} textTransform={"uppercase"}>
              Domains
            </Text>
            <Button
              bg={"white"}
              overflow="hidden"
              variant="outline"
              height={"3.5rem"}
              width={"3.5rem"}
              borderRadius={0}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => navigate("/new-domain")}
            >
              <AddIcon boxSize={4} />
            </Button>
          </Flex>
          {domains && domains.length > 0 ? (
            <DomainList domains={domains} setDomains={setDomains} />
          ) : (
            <Flex
              flexGrow={1}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"1.5rem"}
              marginBottom={"5rem"}
            >
              <Image src={LocalIcons.EmptyDomain} width={134} />
              <Text fontSize={"xl"} color={"#b1b5b8"}>
                YOU AREN’T MANAGING ANY DOMAINS
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Box>
  );
};

export const DomainsPage = withAuthProtection(Page);
