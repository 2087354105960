export const configuration = {
  cedApiKey: "31c57f6a14db95644f0c9392dbc56a73a7eba8ce64a3c045e3062e4d02df5095617bb21dcb7a82aaec755971d63c459705e0",
  environment: "production",
  version: "1.0.27",
  cfApi: {
    hostingUrl: "https://ogun-cf-api.merthin.com",
  },
  frigade: {
    publicApiKey: "api_public_WmvbiDMO7EQkt1haacYnpJT8MYTwHkmfWYThDDVI6r5fNTuwkh8JyIRaySqHsYJ4",
    checklistFlow: "flow_lAkqzsNfFxsvu4DD",
  },
  heroku: {
    hostingUrl: "https://demy-reverseproxy-prod-4ab487532598.herokuapp.com/",
  },
  mockapi: {
    url: "https://mock.merthin.net/api",
    apiKey: "5vapu7ogug5ll6q6zmjd9imk9qjgtv",
  },
  stripe: {
    publishableKey: "pk_live_AAjxCAtmg1yGzZjgLbmy9uDp",
    priceId: "price_1NiNvkK6WVzGeHbBxPd94hgO",
  },
  userRegistry: {
    domain: "demy-reverse-proxy-prod.us.auth0.com",
    managementClientId: "cZ2miswdyMyyHdSNHF8duxOP69m53jnj",
    managementClientSecret: "iKbfIeNIcejYlcX8bjuU5_JLBiLwgVgmf8gyYRYkYURHdWUHYeVzoeV_zFvKLOPg",
    audience: "https://demy-reverse-proxy-prod.us.auth0.com/api/v2/",
  },
};