import { FunctionComponent, useState } from "react";
import { IDomain } from "../../../data/domain";
import {
  Card,
  SimpleGrid,
  Text,
  Box,
  Stack,
  CardBody,
  Heading,
  CardFooter,
  Button,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import React from "react";
import { ogunService } from "../../../services/ogun-service";
import { domainService } from "../../../services/domain-service";

interface DomainListProps {
  domains?: IDomain[];
  setDomains: React.Dispatch<React.SetStateAction<IDomain[] | undefined>>;
}

export const DomainList: FunctionComponent<DomainListProps> = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const [isDeletingDomainLoading, setIsDeletingDomainLoading] = useState(false);

  const handleDeleteDomain = async (domain: IDomain) => {
    setIsDeletingDomainLoading(true);
    const data = await ogunService.deleteDomain(domain.domainURL);
    if (data.data.status === "error") {
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      domainService.deleteDomainByName(domain.domainName);
      props.setDomains(
        props.domains?.filter((x) => x.domainName !== domain.domainName)
      );
    }
    setIsDeletingDomainLoading(false);
    onClose();
  };

  return isDeletingDomainLoading ? (
    <Flex
      position={"absolute"}
      margin={"auto"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent={"center"}
      alignItems={"center"}
      w={"100%"}
    >
      <Spinner thickness="0.25rem" color="#272349" size={"xl"} />
    </Flex>
  ) : (
    <SimpleGrid columns={2} spacing={"2rem"} w={"100%"} height={"fit-content"}>
      {props.domains &&
        Array.isArray(props.domains) &&
        props.domains.map((domain) => {
          return (
            <Card
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="outline"
              height={"15rem"}
            >
              <Box w={"40%"} className="DomainsPageImageBackground" />
              <Stack w={"60%"}>
                <CardBody>
                  <Flex justifyContent={"space-between"}>
                    <Flex flexDir={"column"}>
                      <Heading size="md">{domain.domainName}</Heading>
                      <Text py="2">{domain.domainURL}</Text>
                    </Flex>
                    <Button
                      bg="transparent"
                      borderRadius={100}
                      onClick={onOpen}
                    >
                      <CloseIcon />
                    </Button>
                    <AlertDialog
                      isOpen={isOpen}
                      leastDestructiveRef={cancelRef}
                      onClose={onClose}
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Domain
                          </AlertDialogHeader>
                          <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                          </AlertDialogBody>
                          <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                              Cancel
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() => handleDeleteDomain(domain)}
                              ml={3}
                            >
                              Delete
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </Flex>
                </CardBody>
                <CardFooter>
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => {
                      navigate(`/domain/${domain.domainName}`);
                    }}
                  >
                    Details
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          );
        })}
    </SimpleGrid>
  );
};
