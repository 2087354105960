import iconNotification from "./resources/np_notification.svg";
import iconLogOut from "./resources/np_log-out.svg";
import iconHome from "./resources/np_home.svg";
import iconUser from "./resources/np_user.svg";
import iconClose from "./resources/np_close.svg";
import iconUserV2 from "./resources/np_user2.svg";
import iconDiagonalArrow from "./resources/np_arrow-out.svg";
import iconSuiLogo from "./resources/sui-logo.png";
import iconPolygonLogo from "./resources/polygon-logo.svg";
import iconEthereumLogo from "./resources/ethereum-logo.svg";
import iconEmptyDomain from "./resources/empty-domain.svg";

export const LocalIcons = {
  Close: iconClose,
  DiagonalArrow: iconDiagonalArrow,
  Home: iconHome,
  LogOut: iconLogOut,
  Notification: iconNotification,
  User: iconUser,
  UserV2: iconUserV2,
  SuiLogo: iconSuiLogo,
  PolygonLogo: iconPolygonLogo,
  EthereumLogo: iconEthereumLogo,
  EmptyDomain: iconEmptyDomain,
};
