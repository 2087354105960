import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Image,
  Avatar,
  useBreakpointValue,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import { NavButton } from "./NavButton";
import data from "../../../config/config.json";
import { useNavigate } from "react-router-dom";
import { authentication } from "../../services/authentication";
import { useEffect } from "react";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useDataFetcher } from "../../hooks/use-data-fetcher";
import { stripeService } from "../../services/stripe-service";

export const Navbar = () => {
  const navigate = useNavigate();
  const user = authentication.getUser();
  const analytics = useAnalytics();
  const pathArray = window.location.pathname.split("/");
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const [customer] = useDataFetcher({
    serviceCall: () =>
      stripeService.getCustomer(authentication.getUser()?.email),
  });

  useEffect(() => {
    if (analytics && user) {
      analytics.identify(user.sub, {
        email: user.email,
        name: user.name,
      });
    }
  }, [user?.sub]);

  return (
    <Flex
      as="section"
      minH="100vh"
      bg={data.primaryColor}
      borderRight={"1px dashed #96A8ED"}
    >
      <Flex
        flex="1"
        bg="bg-surface"
        boxShadow="sm"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "2" }}
        px={{ base: "4", sm: "6" }}
        w={isDesktop ? "auto" : "90px"}
      >
        <Stack justify="space-between" spacing="1" width="100%">
          <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
            <Stack align="center">
              <Image
                width={isDesktop ? "6rem" : "7rem"}
                height={isDesktop ? "6.5rem" : "2rem"}
                cursor="pointer"
                style={{
                  objectFit: "contain",
                }}
                src={data.icon}
                alt={data.companyName}
                onClick={() => navigate("/domains")}
              />
            </Stack>
            <Stack spacing="1" gap={"1rem"}>
              {data.Navbar.map((option) => {
                return (
                  <NavButton
                    key={option.slug}
                    aria-current={
                      pathArray[1] === option.displayName ? "page" : "false"
                    }
                    bg={data.secondaryColor}
                    color={data.primaryColor}
                    label={option.displayName}
                    icon={option.icon}
                    onClick={() => {
                      navigate(`/${option.slug}`);
                    }}
                  />
                );
              })}
              {customer && customer !== "" && (
                <NavButton
                  key={"subscriptions"}
                  aria-current={
                    pathArray[1] === "Subscriptions" ? "page" : "false"
                  }
                  bg={data.secondaryColor}
                  color={data.primaryColor}
                  label={"Subscriptions"}
                  icon={"saf"}
                  onClick={() => {
                    navigate(`/subscriptions`);
                  }}
                />
              )}
            </Stack>
          </Stack>
          <Stack spacing={{ base: "5", sm: "6" }}>
            <Button
              color="white"
              bg={data.secondaryColor}
              _hover={{ background: data.secondaryColor }}
              onClick={() => {
                authentication.logout();
              }}
            >
              <Icon
                margin={1}
                as={FiLogOut}
                boxSize="3"
                color={data.primaryColor}
                height={5}
                w={5}
              />
              <Text color={data.primaryColor}>{isDesktop ? "Logout" : ""}</Text>
            </Button>
            <Divider />
            <HStack spacing="3">
              <Avatar
                boxSize="10"
                name={user?.name || ""}
                src={user?.picture || ""}
              />
              {isDesktop && (
                <Box>
                  <Text
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="sm"
                    color={"white"}
                  >
                    {user?.name}
                  </Text>
                  <Text fontSize="sm" color={"white"}>
                    {user?.email}
                  </Text>
                </Box>
              )}
            </HStack>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
