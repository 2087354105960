import { BaseService } from "./api-clients/base-service";
import { BearerTokenApiClient } from "./api-clients/bearer-token-api-client";

export class ValidateUrlService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, "validUrl");
  }

  postValidateUrl(targetUrl: string): Promise<boolean> {
    const url = this.url("");
    return this.apiClient.post(url, {
      url: targetUrl,
    });
  }
}
export const validateUrlService = new ValidateUrlService(
  new BearerTokenApiClient()
);
