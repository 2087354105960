import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { DomainsPage } from "./Pages/DomainsPage/DomainsPage";
import { LoginPage } from "./Pages/LoginPage/LoginPage";
import { LoginCallbackPage } from "./Pages/LoginCallBackPage/LoginCallbackPage";
import { PageNotFoundPage } from "./Pages/PageNotFound/PageNotFound";
import { EmptyLayout } from "./Layouts/EmptyLayout/EmptyLayout";
import { MainLayout } from "./Layouts/MainLayout/MainLayout";
import { DomainDetailPage } from "./Pages/DomainDetailPage/DomainDetailPage";
import { NewDomainPage } from "./Pages/NewDomainPage/NewDomainPage";
import { SubscriptionsPage } from "./Pages/SubscriptionsPage/SubscriptionsPage";

export const Kernel = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="domains" element={<DomainsPage />} />
          <Route path="new-domain" element={<NewDomainPage />} />
          <Route path="domain/:id/" element={<DomainDetailPage />} />
          <Route path="subscriptions" element={<SubscriptionsPage />} />
          <Route index element={<DomainsPage />} />
        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="login-callback" element={<LoginCallbackPage />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
